import React, { useEffect, useState } from 'react';

import { getOrders } from '../../../actions/orders';
import { adminOrder, orderColumns } from '../../../constants/constants';

import MaterialTable, { MTableToolbar } from "material-table";

//CSS
import './Pedidos.css'

//Components
import InfoPedido from './InfoPedido';
import InfoCliente from './InfoCliente';
import TablaPedidos from './TablaPedidos';
import InfoFacturacion from './InfoFacturacion';
import EstadoEnvioMelonn from './EstadoEnvioMelonn';
import EnvioAlmacen from './EnvioAlmacen/EnvioAlmacen';

//MUI
import {
    Box, Card, CardContent, Container, Grid,
    Dialog, AppBar, Toolbar, IconButton,
    Typography, Slide, Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import CheckIcon from '@mui/icons-material/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AdminPedidos = () => {
    const [data, setData] = useState([]);
    const [dialog, setDialog] = useState(false);
    // orden seleccionada
    const [selectedOrder, setSelectedOrder] = useState(adminOrder);

    const { order_num, payment: { method }, date: order_date, odoo_request } = selectedOrder;
    
    // cerrar Dialog
    const handleClose = () => {
        setDialog(false);
    };

    useEffect(() => {
        myOrders();
    }, []);

    // lista de pedidos
    const myOrders = async () => {
        const resp = await getOrders();
        if (resp.status)
            setData(resp.resp);
    };

    // pedido seleccionado
    const selected = (_id) => {
        const _order = data.find(e => e.id === _id);
        if (_order) {
            setSelectedOrder(_order);
            setDialog(true);
        }
    };

    return (
        <>
            <Container maxWidth='xxxl' sx={{ mb: 5 }} style={{ marginTop: '90px' }}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }} gutterBottom>Pedidos</Typography>
                <Box className='register_content' sx={{ mt: 3 }}>
                    <Stack spacing={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                            <Box sx={{ my: 'auto' }} className='register_melonn'></Box>
                            <Typography sx={{ my: 'auto' }}>Registrado en Odoo</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                            <Box sx={{ my: 'auto' }} className='no_register_melonn'></Box>
                            <Typography sx={{ my: 'auto' }}>No registrado en Odoo</Typography>
                        </Box>
                    </Stack>
                    {/* <Stack spacing={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                            <CheckIcon sx={{ my: 'auto', color: 'green' }} />
                            <Typography sx={{ my: 'auto' }}>Registrado en Odoo</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                            <CloseIcon sx={{ my: 'auto', color: 'red' }} />
                            <Typography sx={{ my: 'auto' }}>No registrado en Odoo</Typography>
                        </Box>
                    </Stack> */}
                </Box>
                {/* Tabla de Pedidos */}
                <Box sx={{ width: '100%', height: '100%', mt: 3 }}>
                    <MaterialTable
                        title="Detalles de pedidos"
                        data={data}
                        columns={orderColumns}
                        options={{
                            exportButton: true,
                            filtering: true,
                            headerStyle: {
                                zIndex: 1
                            },
                            rowStyle: rowData => ({
                                backgroundColor:
                                    // rowData.in_melonn ? "#A9DFBF" : "",
                                    rowData.odoo_request &&
                                        rowData.odoo_request.create &&
                                        rowData.odoo_request.create.sale_order_id ? "#A9DFBF" : ""
                            })
                        }}
                        components={{
                            Toolbar: props => (<MTableToolbar {...props} classes={{ root: "dummy-class" }} />),
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: "No hay datos por mostrar",
                                addTooltip: 'Agregar',
                                deleteTooltip: 'Eliminar',
                                editTooltip: 'Editar',
                                filterRow: {
                                    filterTooltip: 'Filtrar'
                                },
                                editRow: {
                                    deleteText: '¿Segura(o) que quiere eliminar?',
                                    cancelTooltip: 'Cancelar',
                                    saveTooltip: 'Guardar'
                                }
                            },
                            grouping: {
                                placeholder: "Arrastre un encabezado aquí para agrupar",
                                groupedBy: 'Agrupado por:'
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'filas',
                                labelRowsPerPage: 'Filas por página:',
                                firstAriaLabel: 'Primera página',
                                firstTooltip: 'Primera página',
                                previousAriaLabel: 'Página anterior',
                                previousTooltip: 'Página anterior',
                                nextAriaLabel: 'Página siguiente',
                                nextTooltip: 'Página siguiente',
                                lastAriaLabel: 'Última página',
                                lastTooltip: 'Última página'
                            },
                            toolbar: {
                                addRemoveColumns: 'Agregar o eliminar columnas',
                                nRowsSelected: '{0} fila(s) seleccionada(s)',
                                showColumnsTitle: 'Mostrar columnas',
                                showColumnsAriaLabel: 'Mostrar columnas',
                                exportTitle: 'Exportar',
                                exportAriaLabel: 'Exportar',
                                exportName: "Exportar como CSV",
                                exportPDFName: "Exportar como PDF",
                                searchTooltip: 'Buscar',
                                searchPlaceholder: 'Buscar'
                            }
                        }}
                        onRowClick={(event, rowData,) => selected(rowData.id)}
                    />
                </Box>
                {/* Fin Tabla de Pedidos */}
            </Container>

            {/* Dialog */}
            <Dialog
                fullScreen
                open={dialog}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: '#04A6E1' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {/* Contenido del dialog */}
                <Container maxWidth="xxl" sx={{ mt: 5, mb: 5 }}>
                    {/* Número del pedido */}
                    <Typography variant="h5" component="div">
                        Pedido: {order_num}
                    </Typography>

                    <Typography variant="button">
                        # Pedido Odoo:
                        {
                            odoo_request &&
                                odoo_request.create &&
                                odoo_request.create.sale_order_name ?
                                ` ${odoo_request.create.sale_order_name}`
                                :
                                ' No registrado'
                        }
                    </Typography>

                    {/* Fecha del pedido */}
                    <Typography variant="subtitle1" component="div">
                        Fecha del pedido: {order_date}
                    </Typography>

                    {/* Método de pago */}
                    <Typography variant="subtitle1" component="div">
                        Pagado con: <b style={{ fontWeight: 'bold', color: '#3498DB' }}>{method}</b>
                    </Typography>

                    <Box sx={{ mt: 5 }}>
                        <Grid container spacing={4}>
                            <Grid item xl={12}>
                                {/* Tabla con productos */}
                                <TablaPedidos
                                    selectedOrder={selectedOrder} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mt: 5 }}>
                        <Grid container spacing={4}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Card className="card__pedidos">
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                            Detalle de facturación
                                        </Typography>
                                        <InfoFacturacion
                                            selectedOrder={selectedOrder} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <Card className="card__pedidos">
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                            Estado del envío
                                        </Typography>
                                        <EstadoEnvioMelonn
                                            selectedOrder={selectedOrder} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Grid container spacing={4}>
                            {/* Información del cliente */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                                <Card className="card__pedidos">
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                            Información del cliente
                                        </Typography>
                                        <InfoCliente
                                            selectedOrder={selectedOrder} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* Fin información del cliente */}

                            {/* Información del pedido */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                                <Card className="card__pedidos">
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                            Información del pedido
                                        </Typography>
                                        <InfoPedido
                                            selectedOrder={selectedOrder} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* Fin información del pedido */}

                            {/* Enviar pedido desde almacén general */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                                <Card className="card__pedidos">
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                            Enviar pedido desde Almacén General
                                        </Typography>
                                        <EnvioAlmacen
                                            setData={setData}
                                            selectedOrder={selectedOrder}
                                            setSelectedOrder={setSelectedOrder} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* Enviar pedido desde almacén general */}
                        </Grid>
                    </Box>
                </Container>
            </Dialog>
        </>
    );
};

export default AdminPedidos;