// import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import AddBlog from '../components/Admin/Blog/AddBlog';
import Coupon from '../components/Admin/coupon/Coupon';
import BlogAdmin from '../components/Admin/Blog/BlogAdmin';
import Branches from '../components/Admin/Branches/Branches';
import Promotion from '../components/Admin/promotion/Promotion';
import FlashSale from '../components/Admin/FlashSale/FlashSale';
import ProductTools from '../components/Admin/Tools/ProductTools';
import AdminPedidos from '../components/Admin/Pedidos/AdminPedidos';
import ResponsiveDrawer from '../components/Admin/ResponsiveDrawer';
import ShopSort from '../components/Admin/Product/Sorting/ShopSort';
import BannersShop from '../components/Admin/BannersShop/BannersShop';
import BlogUpdate from '../components/Admin/Blog/BlogUpdate/BlogUpdate';
import NewBranch from '../components/Admin/Branches/NewBranch/NewBranch';
import CouponSave from '../components/Admin/coupon/couponSave/CouponSave';
import NewProduct from '../components/Admin/Product/NewProduct/NewProduct';
import BrandContainer from '../components/Admin/Product/Brand/BrandContainer';
import AdminsUsersList from '../components/Admin/admin-users/AdminsUsersList';
import ProductList from '../components/Admin/Product/ProductList/ProductList';
import ProductLoad from '../components/Admin/Product/ProductLoad/ProductLoad';
import AdminDirectory from '../components/Admin/AdminDirectory/AdminDirectory';
import DashboardComponent from '../components/Admin/Dashboard/DashboardComponent';
import AddFlashSale from '../components/Admin/FlashSale/AddFlashSale/AddFlashSale';
import ShippingMethods from '../components/Admin/shipping-methods/ShippingMethods';
import OdooProductList from '../components/Admin/Product/OdooProduct/OdooProductList';
import CarritoAbandonado from '../components/Admin/CarritoAbandonado/CarritoAbandonado';
import NewDirectory from '../components/Admin/AdminDirectory/NewDirectory/NewDirectory';
import ProductQualities from '../components/Admin/Product/ProductQualities/ProductQualities';

// import AccountEmail from '../components/Admin/Clients/AccountEmail';

export const AdminRoutes = () => {
    // useEffect(() => {
    //     window.zE('webWidget', 'hide');
    // }, []);

    return (
        <>
            <ResponsiveDrawer />
            <Routes>
                <Route path="/" element={<DashboardComponent />} />
                <Route path="/productos" element={<ProductList />} />
                <Route path='/producto-agregar' element={<NewProduct />} />
                <Route path="/productos-odoo" element={<OdooProductList />} />
                <Route path="/categorias-producto" element={<ProductQualities />} />
                <Route path='/marca-producto' element={<BrandContainer />} />
                <Route path="/orden-productos" element={<ShopSort />} />

                <Route path='/carrito-abandonado' element={<CarritoAbandonado />} />
                <Route path="/cupones" element={<Coupon />} />
                <Route path="/cupones/registrar" element={<CouponSave />} />
                <Route path="/promociones" element={<Promotion />} />
                <Route path="/metodos-envio" element={<ShippingMethods />} />
                <Route path="/admins" element={<AdminsUsersList />} />
                <Route path="/orders" element={<AdminPedidos />} />
                <Route path="/carga-masiva" element={<ProductLoad />} />
                {/* <Route path="/correo-masivo" element={<AccountEmail />} /> */}
                <Route path="/nuevo-blog" element={<AddBlog />} />
                <Route path="/actualizar-blog/:url" element={<BlogUpdate />} />
                <Route path="/blog" element={<BlogAdmin />} />
                <Route path="/ofertas-relampago" element={<FlashSale />} />
                <Route path="/nueva-oferta-relampago" element={<AddFlashSale />} />
                <Route path="/banners-tienda" element={<BannersShop />} />
                <Route path="/directorio-medico" element={<AdminDirectory />} />
                <Route path="/nuevo-medico" element={<NewDirectory />} />
                <Route path="/sucursales" element={<Branches />} />
                <Route path="/nueva-sucursal" element={<NewBranch />} />
                <Route path="/tools" element={<ProductTools />} />
                <Route path="*" element={<Navigate to="/nefro-admin" replace />} />
            </Routes>
        </>
    );
};