import React, { useEffect, useState, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';

import './ProductSample.css';

import {
    basicMsg
    // cartDialog
} from '../../../actions/swal_msg';
import { commentModalToggle } from '../../../actions/ui';
import {
    navigateToProductPage,
    saveItemToCart,
    shopClearAction,
    shopProductViewed
} from '../../../actions/shop';

// import { NavContext } from '../../../context/NavContext';

import { regex_num } from '../../../constants/regex';
import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';
import { prodInit, varInit } from '../../../constants/shop/shop_constants';

import payment_1 from '../../../assets/images/FormasDePago/visa_2.png';
import payment_2 from '../../../assets/images/FormasDePago/mastercard_2.png';
import payment_3 from '../../../assets/images/FormasDePago/mercado-pago_2.png';
import payment_4 from '../../../assets/images/FormasDePago/Paypal_2.png';
import payment_5 from '../../../assets/images/FormasDePago/oxxo_2.png';
// componentes
import ProductsFBT from './ProductsFBT';
import ReviewModal from './Review/ReviewModal';
import AddToCartDialog from './AddToCartDialog';
import ProductReview from './Review/ProductReview';
import RecentlyViewed from '../RecentlyViewed/RecentlyViewed';

//gallery
import ImageGallery from 'react-image-gallery';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';
import shape from '../../../assets/resources/shape.png';

//MUI
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import {
    Typography, Breadcrumbs, Backdrop, CircularProgress,
    Stack, Container, Grid, Box, TextField, Button,
    AccordionSummary, AccordionDetails, FormControl,
    useMediaQuery,
    // FormGroup, FormControlLabel, Checkbox,
    // Chip, Tab, Tooltip, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
// import PhoneIcon from '@mui/icons-material/Phone';
import MuiAccordion from '@mui/material/Accordion';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import ReactImageMagnify from 'react-image-magnify';

// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { TabContext, TabList, TabPanel } from '@mui/lab';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderTop: `1px solid #CACFD2`,
    borderBottom: `1px solid #CACFD2`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const WhatsAppButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    height: 40,
    color: '#fff',
    backgroundColor: '#25d366',
    '&:hover': {
        backgroundColor: '#52BE80',
        color: '#fff'
    },
}));

// const PhoneButton = styled(Button)(({ theme }) => ({
//     textTransform: 'none',
//     height: 40,
//     color: '#fff',
//     backgroundColor: '#C0392B',
//     '&:hover': {
//         backgroundColor: '#E74C3C',
//         color: '#fff'
//     }
// }));

const openAdd = {
    open: true,
    action: 'add',
    loading: false
};

const tagManagerArgs = {
    gtmId: 'GTM-KQMW2JG'
};
TagManager.initialize(tagManagerArgs);

// const images = [
//     {
//         img: full_1
//     },
//     {
//         img: full
//     },
//     {
//         img: full_2
//     }
// ]

const ProductSample = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { setValue } = useContext(NavContext);

    const matches = useMediaQuery('(max-width: 950px)');
    const matches_1 = useMediaQuery('(max-width: 410px)');

    // ruta anterior
    const { state, pathname } = useLocation();
    // URL producto
    const { product: product_url } = useParams();

    // productos de tienda y datos de navegación
    const { action, categories, products, navigation, promotions } = useSelector(state => state.shop);
    const { nav_id } = navigation; // _id de variante a mostrar

    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { _id: account_id, username: logged_user } = loggedUser;

    // producto y variantes
    const [product, setProduct] = useState(prodInit);
    const {
        _id: prod_id, title, url, info, images, category,
        prod_category, refrigerated, medicine, prod_variants,
        reviews
    } = product;
    console.log(product)
    // promedio de reseñas
    const [avgReviews, setAvgReviews] = useState(0);

    // variante activa
    const [variant, setVariant] = useState(varInit);
    const {
        _id: prod_var_id, variant_title, price,
        stock, description, item_promotion
    } = variant;

    // imágenes de variante activa
    const [currentImages, setCurrentImages] = useState([]);
    // cantidad
    const [qty, setQty] = useState(1);
    // URL ruta anterior
    const [categoryUrl, setCategoryUrl] = useState('');

    // deshabilita realizar otra reseña
    const [enabled, setEnabled] = useState(true);
    // productos comprados juntos frecuentemente
    const [fbtItems, setFBTItems] = useState([]);

    // categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        // obtenemos categoría mediante la ruta previa
        if (state) // state = { previousPath : "/tienda/category" }
            setCategoryUrl(state.previousPath.split('/').pop());
    }, []);

    useEffect(() => {
        const found = categories.find(e => e.url === categoryUrl);
        if (found)
            setSelectedCategory(found.category);
    }, [categoryUrl, categories]);

    // lista de productos actualizada / cambió url de producto
    useEffect(() => {
        if (products.length > 0) {
            const ProductModel = products.find(e => e.url === product_url);
            if (ProductModel) {
                const {
                    _id, title, url, prod_variants, reviews
                } = ProductModel;
                setProduct(ProductModel);

                if (prod_variants.length > 0) { // variantes disponibles
                    // elegir variante a mostrar
                    const VariantModel = nav_id !== ''
                        // se accedió desde carrito (se conoce la variante)
                        ? prod_variants.find(e => e._id === nav_id)
                        // se accedió desde tienda
                        : prod_variants[0];
                    const {
                        _id: prod_var_id, variant_title, price,
                        iva, stock, barcode, images, item_promotion
                    } = VariantModel;
                    // variante a mostrar
                    setVariant({ ...VariantModel, checked: true });

                    dispatch(shopProductViewed(account_id,
                        {
                            prod_id: _id, prod_var_id,
                            title, variant_title, url,
                            price: ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo)
                                ? item_promotion.price
                                : price,
                            iva, stock, images,
                            reviews, item_promotion
                        }
                    ));

                    if (images.length > 0)
                        setCurrentImages(images.map(e =>
                        ({
                            original: `${GOOGLE_DRIVE_CDN}${e.id}`,
                            thumbnail: `${GOOGLE_DRIVE_CDN}${e.id}`
                        })
                        ));
                    else
                        setCurrentImages([{
                            original: no_image,
                            thumbnail: no_image
                        }]);
                    // incluir código de barras en URL
                    navigate(`${pathname}?variant=${barcode}`, { replace: true });
                }
                // crear nuevo arreglo e incluir propiedad 'checked'
                if (ProductModel.fbt_item.length > 0)
                    setFBTItems(ProductModel.fbt_item);
            }
        }
        // quizá mover de lugar
        dispatch(navigateToProductPage({ nav_url: '', nav_id: '' }));
    }, [product_url, products]);

    // cambios en reseñas de producto
    useEffect(() => {
        if (reviews.length > 0) { // existen reseñas
            let avg = 0; // promedio reseñas
            let count = 0 // número reseñas
            reviews.forEach(element => {
                avg += element.rating;
                count++;
            });
            // redondea promedio de reseñas
            avg = Math.round(avg / count);
            setAvgReviews(avg);
            reviews.map(({ username }) => (
                // ya ha realizado reseña
                username === logged_user && setEnabled(false)
            ));
        }
    }, [reviews]);

    // producto agregado a carrito
    useEffect(() => {
        if (action !== '') {
            handleClickOpen();
        }
    }, [action]);


    // const showDialog = async () => {
    //     // filtrar promociones de producto agregado al carrito
    //     const ActivePromotions = promotions.filter(
    //         ({ variant_a, variant_b, variant_c }) =>
    //             [variant_a._id, variant_b._id, variant_c._id].includes(prod_var_id)
    //     );

    //     // mensaje con promociones
    //     let txt = ActivePromotions.length > 0 ?
    //         `<p style="text-align: start; font-size: 0.8em">${ActivePromotions.length > 1 ? 'Promociones' : 'Promoción'
    //         } en tienda:</p>`
    //         :
    //         '';
    //     if (ActivePromotions.length > 0) {
    //         txt += '<ul>';
    //         for (const e of ActivePromotions) {
    //             txt += `<li style="font-size:0.8em">${e.info}.</li>`;
    //         };
    //         txt += '</ul>';
    //     };

    //     let html = '<h6>¿Desea proceder al carrito?</h6><br>'; // action === success
    //     if (action === 'exceeds-stock')
    //         html = '<h6>Hicimos ajustes en su carrito dado que supera existencias en producto. ¿Desear proceder al carrito?</h6><br>';
    //     else if (action === 'promo-limit-2x1')
    //         html = '<h6>Debido a promoción 2x1, mantiene sólo 2 unidades en carrito. ¿Desear proceder al carrito?</h6><br>';
    //     else if (action === 'promo-limit-4x3')
    //         html = '<h6>Debido a promoción 4x3, mantiene sólo 4 unidades en carrito. ¿Desear proceder al carrito?</h6><br>';
    //     html += txt;
    //     if (await cartDialog('Producto agregado', html, 'Mi carrito', 'Seguir comprando')) {
    //         navigate('/carrito');
    //         setValue('/carrito');
    //     }
    // };

    // cantidad de producto a agregar
    const handleChangeQty = ({ target }) => {
        const value = target.value;
        const _num = Number(target.value);
        if (value.trim() === '' ||
            (regex_num.test(value) && _num > 0))
            setQty(value);
    };

    // agregar al carrito
    const handleSubmit = (event) => {
        event.preventDefault();
        const _qty = Number(qty);
        if (_qty > 0) {
            if (stock <= 0)
                basicMsg('No hay stock disponible');
            else {
                const model = {
                    prod_id, prod_var_id, quantity: _qty,
                    refrigerated, medicine
                };
                dispatch(saveItemToCart(model, 'dialog', () => { }));
                // Tag Manager
                window.dataLayer.push({
                    event: 'add-to-cart'
                });
            }
        }
    };

    // comprar ahora
    const handleBuyNow = (event) => {
        event.preventDefault();
        const _qty = Number(qty);
        if (_qty > 0) {
            if (stock <= 0)
                basicMsg('No hay stock disponible');
            else {
                const model = {
                    prod_id, prod_var_id, quantity: _qty,
                    refrigerated, medicine
                };
                dispatch(saveItemToCart(model));
                // Tag Manager
                window.dataLayer.push({
                    event: 'add-to-cart'
                });
                setTimeout(() => {
                    navigate('/carrito')
                }, 500)
            }
        }
    };

    // variante seleccionada
    const variantSelect = VariantModel => () => {
        const {
            _id: prod_var_id, variant_title, price,
            iva, stock, barcode, images, item_promotion
        } = VariantModel;

        setVariant({ ...VariantModel, checked: true });
        // nueva variante vista
        dispatch(shopProductViewed(account_id,
            {
                prod_id, prod_var_id,
                title, variant_title, url,
                price: ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo)
                    ? item_promotion.price
                    : price,
                iva, stock, images,
                reviews, item_promotion
            }
        ));

        // actualizar URL
        navigate(`${pathname}?variant=${barcode}`, { replace: true });
        if (images.length > 0)
            setCurrentImages(images.map(e =>
            ({
                original: `${GOOGLE_DRIVE_CDN}${e.id}`,
                thumbnail: `${GOOGLE_DRIVE_CDN}${e.id}`
            })
            ));
        else
            setCurrentImages([{
                original: no_image,
                thumbnail: no_image
            }]);
    };

    // abre modal para añadir comentario
    const openModal = () => {
        dispatch(commentModalToggle(openAdd));
    };

    //Show more or less
    const [itemsToShow, setItemsToShow] = useState(5);
    const showmore = () => {
        setItemsToShow(itemsToShow + 5)
    }
    const showless = () => {
        setItemsToShow(5);
        handleScrollTo();
    }

    const ref = useRef(null);

    const handleScrollTo = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // const per = (price / 100) * 10;
    // const [time, setTime] = useState(3)
    // const handleChangeTime = (e) => {
    //     setTime(e.target.value)
    // }

    // const [tab, setTab] = useState("1");

    // const handleChangeTab = (event, newValue) => {
    //     setTab(newValue);
    // };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        dispatch(shopClearAction(''));
    };

    const ActivePromotions = promotions.filter(
        ({ variant_a, variant_b, variant_c }) =>
            [variant_a._id, variant_b._id, variant_c._id].includes(prod_var_id)
    );

    return (
        prod_id !== '' ? (
            <>
                <Container maxWidth='xxl' sx={{ mt: matches ? 5 : 18 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit"
                            to="/tienda">Tienda
                        </Link>
                        {
                            selectedCategory !== '' &&
                            <Link underline="hover" color="inherit"
                                to={`/tienda/categoria/${categoryUrl}`}>
                                { selectedCategory }
                            </Link>
                        }
                        <Typography color="text.primary">{title}</Typography>
                    </Breadcrumbs>
                </Container>

                {/* Producto */}
                <Container maxWidth='xxl' sx={{ mt: 6 }}>
                    <Grid container spacing={5}>
                        <Grid item xl={4.5} lg={6} md={6} sm={12} xs={12}>
                            <div className='product_gallery'>
                                <div className='img_product'>
                                    {
                                        matches ? <div id='portal'></div> : null
                                    }
                                    <ImageGallery items={currentImages} showPlayButton={false} useBrowserFullscreen={false}
                                        showFullscreenButton={false} thumbnailPosition={matches ? 'bottom' : 'left'} showNav={false}
                                        renderItem={
                                            (item) =>
                                                <ReactImageMagnify
                                                    {...{
                                                        smallImage: {
                                                            alt: 'nfps_prod',
                                                            isFluidWidth: true,
                                                            src: item.original,
                                                        },
                                                        largeImage: {
                                                            src: item.original,
                                                            width: 1200,
                                                            height: 1200,
                                                        },
                                                        enlargedImagePortalId: 'portal',
                                                        enlargedImageContainerDimensions: {
                                                            width: matches ? '105%' : '130%',
                                                            height: matches ? '100%' : '130%'
                                                        }
                                                    }}
                                                />
                                        }
                                    />
                                </div>

                                {
                                    item_promotion.promo !== '' &&
                                    <>
                                        {
                                            ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo)
                                                ?
                                                <div className="product_top-image">
                                                    <img src={shape} alt={shape} width='90' style={{ pointerEvents: 'none' }} />
                                                    <div className="product_centered_image">
                                                        <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                            {item_promotion.text}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                :
                                                <div className="product_top-image">
                                                    <img src={shape} alt={shape} width='90' style={{ pointerEvents: 'none' }} />
                                                    <div className="product_centered_image">
                                                        <Typography className='no_promotion_text' sx={{ color: '#fff', fontSize: '14px', fontWeight: 'bold' }}>
                                                            Promoción
                                                        </Typography>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                }
                                <Typography
                                    sx={{ mt: 1.5, fontWeight: '500' }}
                                    variant='body1'
                                    textAlign='center'>
                                    {matches ? 'Mantén tu dedo sobre la imagen para hacer zoom' : 'Pasa el mouse encima de la imagen para aplicar zoom'}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xl={4.5} lg={6} md={6} sm={12} xs={12}>
                            {
                                matches ? null : <div id='portal'></div>
                            }

                            {/* Nombre del producto */}
                            <Typography variant="h5" sx={{ fontWeight: '500', mt: { xs: 1, sm: 1, md: 0 } }}>{title}</Typography>
                            {/* Fin Nombre del producto */}

                            {/* Rating del producto */}
                            <Box sx={{ mt: .5 }}>
                                {
                                    reviews.length > 0 ?
                                        <Box sx={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ref.current?.scrollIntoView({ behavior: 'smooth' }) }}>
                                            <div className="ratings-product">
                                                {
                                                    Array.from(Array(avgReviews), (e, j) => {
                                                        return <i className="fas fa-star"
                                                            key={j}
                                                            style={{
                                                                marginRight: '4.5px',
                                                                color: '#FFA41C',
                                                                stroke: "#FFA41C",
                                                            }}></i>
                                                    })
                                                }
                                            </div>
                                            <Box sx={{ ml: 1.5, mt: .5 }}>
                                                <Typography sx={{ fontSize: '12px', color: '#707070', fontWeight: '600' }}>({reviews.length} comentarios)</Typography>
                                            </Box>
                                        </Box>

                                        :
                                        <Box sx={{ display: 'flex', gap: '10px' }}>
                                            <div className="ratings-product" style={{ cursor: 'pointer' }} onClick={() => { ref.current?.scrollIntoView({ behavior: 'smooth' }) }}>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                            </div>
                                            <Box sx={{ my: 'auto' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#707070', fontWeight: '600', cursor: 'pointer' }}
                                                    onClick={() => { ref.current?.scrollIntoView({ behavior: 'smooth' }) }}
                                                >
                                                    (0 comentarios)
                                                </Typography>
                                            </Box>
                                        </Box>
                                }
                            </Box>
                            {/* Fin Rating del producto */}

                            {/* Precio */}
                            {
                                ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo) ?
                                    <Stack direction="row"
                                        justifyContent="flex-start"
                                        alignItems="baseline"
                                        spacing={2}>
                                        <Typography variant="h5" sx={{
                                            color: '#000000', fontWeight: 'normal', mt: 1.5,
                                            textDecoration: 'line-through'
                                        }}>
                                            ${price}
                                        </Typography>
                                        <Typography variant="h5" sx={{ color: '#F90606', fontWeight: 'normal', mt: 1.5 }}>
                                            ${item_promotion.price}
                                        </Typography>
                                    </Stack>
                                    :
                                    <Typography variant="h5" sx={{ color: '#F90606', fontWeight: 'normal', mt: 1.5 }}>
                                        ${price}
                                    </Typography>
                            }
                            {/* Fin Precio */}

                            {/* Variantes */}
                            <Box sx={{ mt: 1.5, mb: 2.5 }}>
                                {
                                    prod_variants.length > 1 &&
                                    <>
                                        <Typography variant='body1' gutterBottom>Tipos:</Typography>
                                        <Stack direction="row" spacing={1.5}>
                                            {
                                                prod_variants.map(e => {
                                                    return (
                                                        <Button
                                                            key={e._id}
                                                            variant={prod_var_id === e._id ? "contained" : "outlined"}
                                                            onClick={variantSelect(e)}
                                                            sx={{ textTransform: 'lowercase' }}
                                                        >
                                                            {e.variant_title}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </Stack>
                                    </>
                                }
                            </Box>
                            {/* Fin Variantes */}

                            {/* {
                                item_promotion.promo !== '' &&
                                <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;', mt: 2, borderRadius: '8px' }}>
                                    <Alert
                                        severity="info"
                                        icon={<InventoryIcon />}
                                    >
                                        Promociones disponibles
                                    </Alert>
                                    <Box sx={{ p: 2 }}>
                                        <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                                <IconButton className='promotions-swiper-button-prev' size='small' disabled={ActivePromotions.length <= 1}>
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                                                <Swiper
                                                    breakpoints={{
                                                        0: {
                                                            slidesPerView: 1,
                                                        },
                                                        610: {
                                                            slidesPerView: 1,
                                                        },
                                                        800: {
                                                            slidesPerView: 1,
                                                        },
                                                        950: {
                                                            slidesPerView: 1,
                                                        },
                                                        1100: {
                                                            slidesPerView: 1,
                                                        },
                                                        1200: {
                                                            slidesPerView: 1,
                                                        },
                                                    }}
                                                    spaceBetween={40}
                                                    autoHeight={true}
                                                    navigation={{
                                                        nextEl: '.promotions-swiper-button-next',
                                                        prevEl: '.promotions-swiper-button-prev',
                                                    }}
                                                    loop={true}
                                                    pagination={{
                                                        clickable: true,
                                                        dynamicBullets: true,
                                                    }}
                                                    modules={[Navigation, Pagination]}
                                                >
                                                    {
                                                        ActivePromotions.map((res) => (
                                                            <SwiperSlide>
                                                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '15px', my: 3 }}>
                                                                    <Box sx={{ my: 'auto' }}>
                                                                        <img className="image_tog_prom" width='120' height="120"
                                                                            style={{ objectFit: 'contain' }} alt={pro_pru}
                                                                            src={pro_pru}
                                                                        />
                                                                    </Box>

                                                                    {
                                                                        Object.keys(res.variant_b).length > 0 &&
                                                                        <Box sx={{ my: 'auto' }}>
                                                                            <AddIcon fontSize='small' />
                                                                        </Box>
                                                                    }

                                                                    {
                                                                        Object.keys(res.variant_b).length > 0 &&
                                                                        <Box sx={{ my: 'auto' }}>
                                                                            <img className="image_tog_prom" width='120' height="120"
                                                                                style={{ objectFit: 'contain' }} alt={pro_pru_1}
                                                                                src={pro_pru_1}
                                                                            />
                                                                        </Box>
                                                                    }

                                                                    {
                                                                        Object.keys(res.variant_c).length > 0 &&
                                                                        <Box sx={{ my: 'auto' }}>
                                                                            <AddIcon fontSize='small' />
                                                                        </Box>
                                                                    }

                                                                    {
                                                                        Object.keys(res.variant_c).length > 0 &&
                                                                        <Box sx={{ my: 'auto' }}>
                                                                            <img className="image_tog_prom" width='120' height="120"
                                                                                style={{ objectFit: 'contain' }} alt={pro_pru_2}
                                                                                src={pro_pru_2}
                                                                            />
                                                                        </Box>
                                                                    }
                                                                </Box>
                                                                <Typography variant='body2' paragraph>
                                                                    {res.info}
                                                                </Typography>
                                                            </SwiperSlide>
                                                        ))
                                                    }
                                                </Swiper>
                                            </Grid>
                                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                                <IconButton className='promotions-swiper-button-next' size='small' disabled={ActivePromotions.length <= 1}>
                                                    <ChevronRightIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            } */}

                            {/* Categorias y etiquetas */}
                            <Box sx={{ mt: 3 }}>
                                <Typography variant='body1'>Categorías:
                                    {
                                        (prod_category && prod_category.length > 0) ?
                                            prod_category.map(({ category }, i) =>
                                                <Link key={i}
                                                    style={{ marginLeft: '3px' }}
                                                    to={
                                                        `/tienda/categoria/${
                                                            category.replace(/\s+/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                                                        }`
                                                    }>
                                                    {
                                                        i === prod_category.length - 1
                                                            ? category : `${category}, `
                                                    }
                                                </Link>
                                            )
                                        :
                                            (category && category.length > 0) ?
                                                category.map((e, i) =>
                                                    <Link key={i}
                                                        style={{ marginLeft: '3px' }}
                                                        to={
                                                            `/tienda/categoria/${
                                                                e.replace(/\s+/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                                                            }`
                                                        }>
                                                        {
                                                            i === category.length - 1
                                                                ? e : `${e}, `
                                                        }
                                                    </Link>
                                                )
                                            :
                                                ` N/A`
                                    }
                                </Typography>
                            </Box>
                            {/* fin Categorias y etiquetas */}

                            {/* Descripción */}
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ mt: 2.5 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                        Descripción
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant='body1'>{description}</Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* Facturación */}
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                        Facturación
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Puedes solicitar una factura electrónica para todos tus pedidos al momento de realizar tu pedido. Para hacerlo
                                        sólo debes llenar todos tus datos en el apartado de facturación del checkout, adjuntando tu Constancia de
                                        Situación Fiscal actualizada. De no obtener los datos completos, tu factura no podrá realizarse correctamente.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* información de envíos */}
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='body1' sx={{ fontWeight: '600' }}>Información de envíos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        Nuestro servicio de envíos se encuentra disponible únicamente en México.
                                    </Typography>
                                    <Typography paragraph>
                                        El tiempo de entrega podrá ser de <b>1 a 7 días hábiles</b>, contados a partir de que se ha recibido el
                                        importe completo de la compra. El tiempo de entrega dependerá del código postal del destino y de la
                                        paquetería. En el caso de códigos postales de zonas extendidas, la garantía de entrega no podrá
                                        aplicarse.
                                    </Typography>
                                    <Typography paragraph>
                                        Si tu código postal tiene cobertura con entrega a domicilio, el paquete se te
                                        entregará hasta la puerta de tu casa. Si, por el contrario, tu domicilio es de
                                        zona extendida o de difícil acceso, el paquete llegará a la oficina ocurre más
                                        cercana y tendrás cierto tiempo para recoger el pedido.
                                    </Typography>
                                    <Typography>
                                        El costo de envio estándar es de 170 pesos. Para las compras mayores a 1500 pesos, o en
                                        artículos seleccionados, el envío no tendrá costo. La elección de la paquetería será la
                                        que consideremos más adecuada y tenga la cobertura hacia su código postal.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>
                        <Grid item xl={3} lg={12} md={12} sm={12} xs={12}>
                            <Box sx={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;rgba(149, 157, 165, 0.2) 0px 8px 24px;', borderRadius: '8px', p: 3 }}>
                                {
                                    stock >= 1
                                        ?
                                        <Typography variant='body' sx={{ fontSize: '19px', color: '#56C596', fontWeight: 'bold' }}>Disponible para compra</Typography>
                                        :
                                        (refrigerated || medicine)
                                            ?
                                            null
                                            :
                                            <Typography variant='body' sx={{ fontSize: '18px', color: '#e74c3c', fontWeight: 'bold' }}>Producto temporalmente agotado</Typography>
                                }

                                {/* Cantidad y add-cart */}
                                <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                                    {
                                        (!refrigerated && !medicine) &&
                                        <>
                                            <FormControl fullWidth>
                                                <TextField
                                                    label="Cantidad"
                                                    variant="outlined"
                                                    value={qty}
                                                    size="small"
                                                    inputProps={{ maxLength: 4 }}
                                                    onChange={handleChangeQty}
                                                />
                                            </FormControl>
                                            <Typography variant="body2" display="block" sx={{ mt: .5 }}>
                                                ({stock} disponibles)
                                            </Typography>
                                        </>
                                    }
                                    {
                                        (refrigerated || medicine) ?
                                            <Box sx={{ my: 3 }}>
                                                <Typography variant="body1" paragraph>
                                                    Producto no disponible en tienda en línea, sin embargo, puedes ADQUIRIRLO por medio de WhatsApp.
                                                </Typography>
                                                <Stack direction={ matches_1 ? 'column' : 'row' } spacing={2}>
                                                    <WhatsAppButton
                                                        variant='contained'
                                                        startIcon={<WhatsAppIcon />}
                                                        fullWidth
                                                        LinkComponent='a'
                                                        href={
                                                            `https://api.whatsapp.com/send?phone=523324966190&text=Hola,+quisiera+adquirir+${variant_title ?
                                                                `${title}+${variant_title}`.trim().replace(/\s/g, '+')
                                                                :
                                                                `${title}`.trim().replace(/\s/g, '+')
                                                            }+(${qty > 1 ? `${qty}+piezas` : '1+pieza'
                                                            })`
                                                        }
                                                        target="_blank" rel="noopener noreferrer"
                                                    >
                                                        Contactar a un asesor
                                                    </WhatsAppButton>
                                                    {/* <PhoneButton
                                                        variant='contained'
                                                        startIcon={<PhoneIcon />}
                                                        fullWidth
                                                        LinkComponent='a'
                                                        href='tel:3324966190'
                                                    >
                                                        Llamar
                                                    </PhoneButton> */}
                                                </Stack>
                                            </Box>
                                        :
                                            <Box>
                                                <Button type="submit"
                                                    variant='contained'
                                                    disabled={stock < 1}
                                                    sx={{ mt: 2, fontWeight: 'bold', width: '100%', height: '50px', textTransform: 'none' }}>
                                                    Agregar al carrito
                                                </Button>
                                                <Button
                                                    onClick={handleBuyNow}
                                                    type="submit"
                                                    variant='outlined'
                                                    disabled={stock < 1}
                                                    sx={{ mt: 2, fontWeight: 'bold', width: '100%', height: '50px', textTransform: 'none' }}>
                                                    Comprar ahora
                                                </Button>
                                            </Box>
                                    }
                                </form>
                                {/*Fin Cantidad y add-cart */}

                                {
                                    (refrigerated || medicine)
                                        ?
                                        null
                                        :
                                        <Box sx={{ mt: 3 }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    <LocalShippingIcon sx={{ color: '#5552B2' }} />
                                                </Box>
                                                <Box sx={{ my: 'auto' }}>
                                                    <Typography variant='body2'>
                                                        <span style={{ fontWeight: 'bold', color: '#5552B2' }}>Envío gratis.</span> En compras mayores a $1,500
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', mt: 3 }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    <CreditCardIcon sx={{ color: '#5552B2' }} />
                                                </Box>
                                                <Box sx={{ my: 'auto' }}>
                                                    <Typography variant='body2'>
                                                        <span style={{ fontWeight: 'bold', color: '#5552B2' }}>Paga en linea y recibe en casa.</span> Todos los medios de pago
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', mt: 3 }}>
                                                <Box sx={{ my: 'auto' }}>
                                                    <LockIcon sx={{ color: '#5552B2' }} />
                                                </Box>
                                                <Box sx={{ my: 'auto' }}>
                                                    <Typography variant='body2'>
                                                        <span style={{ fontWeight: 'bold', color: '#5552B2' }}>Compras Seguras.</span> Compras protegidas siempre
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                }

                                {
                                    (refrigerated || medicine)
                                        ?
                                        null
                                        :
                                        // <Box sx={{ mt: 3 }}>
                                        //     <Typography variant='body1' sx={{ fontWeight: '600' }} paragraph>Medios de pago</Typography>
                                        //     <Box sx={{ width: { xl: '100%', lg: '50%', md: '75%', sm: '100%', xs: '100%' } }}>
                                        //         <img src={payments_1} alt='métodos de pago' width='100%' style={{ pointerEvents: 'none' }} />
                                        //     </Box>
                                        // </Box>
                                        <Box sx={{ mt: 3 }}>
                                            <Typography variant='body1' sx={{ fontWeight: '600' }} paragraph>Métodos de pago</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px', flexWrap: 'wrap' }}>
                                                <Box>
                                                    <img src={payment_1} alt={payment_1} width='80' />
                                                </Box>

                                                <Box>
                                                    <img src={payment_2} alt={payment_2} width='80' />
                                                </Box>

                                                <Box>
                                                    <img src={payment_3} alt={payment_3} width='80' />
                                                </Box>

                                                <Box>
                                                    <img src={payment_4} alt={payment_4} width='80' />
                                                </Box>

                                                <Box>
                                                    <img src={payment_5} alt={payment_5} width='80' />
                                                </Box>
                                            </Box>
                                        </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                {/*Fin producto */}

                {/* Información adicional del producto */}
                {
                    info !== '' || images.length > 0
                        ?
                        <Container maxWidth='xxl' sx={{ mt: 6 }}>
                            <Typography sx={{ fontWeight: '900', textTransform: 'none', fontSize: '28px' }} paragraph>
                                Información adicional
                            </Typography>
                            {
                                info !== ''
                                    ?
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant='body1'>{info}</Typography>
                                    </Box>
                                    :
                                    null
                            }
                            {
                                images.length > 0 &&
                                    <Grid container spacing={4}>
                                        {
                                            images.map((e) => (
                                                <Grid item xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <img src={`${GOOGLE_DRIVE_CDN}${e}`} width='100%' alt='imagen-producto' />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                            }
                        </Container>
                        :
                        null
                }

                <Container maxWidth="xxl" sx={{ mt: 6 }}>
                    {/* Producto en compras frecuentes */}
                    <ProductsFBT
                        fbtItems={fbtItems}
                        setFBTItems={setFBTItems}
                        product={product}
                        variant={variant}
                        setVariant={setVariant} />
                    {/* Fin Producto en compras frecuentes */}
                </Container>

                {/* Vistos recientemente */}
                <Container maxWidth='xxl' sx={{ mt: 5 }}>
                    <RecentlyViewed product_id={prod_id} />
                </Container>

                {/* Comentarios */}
                <Container maxWidth='xl' className="last-view" sx={{ mt: 6 }} ref={ref}>
                    <Typography variant='h5' gutterBottom>Comentarios de clientes</Typography>
                    {
                        account_id !== '0' ?
                            enabled &&
                            <Button variant="contained"
                                onClick={openModal}>
                                Agregar comentario
                            </Button>
                            :
                            <Stack direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2} sx={{ 'fontStyle': 'italic' }}>
                                <Typography variant="body2" gutterBottom>
                                    Es necesario estar registrado para añadir un comentario
                                </Typography>
                                <Button variant="contained"
                                    onClick={() => navigate("/signup", { state: { page: `tienda/producto/${product_url}` } })}>
                                    Registrarme
                                </Button>
                            </Stack>
                    }
                    {/* Reviews */}
                    {
                        reviews.slice(0, itemsToShow).map(e =>
                            <ProductReview key={e._id} review={e} prod_id={prod_id} />
                        )
                    }
                    {
                        reviews.length > 5
                            ?
                            <div style={{ textAlign: 'center', marginTop: '25px' }}>
                                {
                                    (itemsToShow < reviews.length)
                                        ?
                                        <Button variant="outlined" onClick={showmore}>Mostrar más</Button>
                                        :
                                        <Button variant="outlined" onClick={showless}>Mostrar menos</Button>
                                }
                            </div>
                            :
                            null
                    }
                    {/* Reviews */}
                </Container>
                {/* Fin Comentarios */}

                <ReviewModal prod_id={prod_id} />

                <AddToCartDialog
                    ActivePromotions={ActivePromotions}
                    open={open}
                    item_promotion={item_promotion}
                    handleClose={handleClose}
                    action={action}
                />
            </>
        )
            :
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}>
                <CircularProgress />
            </Backdrop>
    );
};

export default ProductSample;